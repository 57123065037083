function toast(type) {
  const toastConfig = {
    success: {
      title: 'Success',
      description: 'Your request was completed.',
      icon: 'i-ri-checkbox-circle-line',
      color: 'green'
    },
    error: {
      title: 'Something Went Wrong',
      description: 'Oops! Something went wrong. Please try again later.',
      icon: 'i-ri-error-warning-line',
      color: 'red'
    },
    warning: {
      title: 'Warning',
      icon: 'i-ri-error-warning-line',
      color: 'orange'
    },
    info: {
      title: 'Information',
      icon: COMMON_ICONS.information,
      color: 'heroblue'
    }
  };

  return {
    add: ({title, description, actions, id = generateUUID(), timeout} = {}) => {
      const config = toastConfig[type] || {};
      useToast().add({
        title: title || config.title,
        description: description || config.description,
        actions,
        id,
        timeout,
        icon: config.icon,
        color: config.color
      });
    }
  };
}

export function useSuccessToast() {
  return toast('success');
}

export function useErrorToast() {
  return toast('error');
}

export function useWarningToast() {
  return toast('warning');
}

export function useInfoToast() {
  return toast('info');
}
